import { OverridesStyleRules } from '@mui/material/styles/overrides';
import { DataGridProProps, GridRowHeightReturnValue } from '@mui/x-data-grid-pro';
import './fonts.css';

export enum ColumnTypeIdentifier {
  SUBTOTAL = 'Subtotal',
  PRICE = 'Price',
  MULTIPLIER = 'Multiplier',
  TEXT = 'Text',
  HANDLER = 'Handler',
}

const COLORS = {
  HoverNFocus: '#49D8F1',
  white: '#ffffff',
  footerHeader: '#00658C',
  subtotalTaxDiscount: '#001E2D',
  subtotalTaxDiscountBg: '#EAF5FF',
  borderColor: '#13BCD5',
};

const SIZES = {
  defaultRowHeight: '52px',
  defaultRowFooterHeight: '40px',
  defaultFontWeight: 400,
  columnHeaderHeight: 33,
};

const STYLES = {
  blueBorderStyle: `1px solid ${COLORS.HoverNFocus}`,
  focusedBoxShadow: '0px 1px 2px rgba(0, 0, 0, 0.30), 0px 1px 3px rgba(0, 0, 0, 0.15)',
};

const getRowClassName = (params) => {
  if (params.row.rowType === TableRowTypes().HEADER) return 'py-header-row';
  if (params.row.rowType === TableRowTypes().FOOTER) return 'py-footer-row';
  if (params.row.rowType === TableRowTypes(true)['TOTAL']) return 'py-total-row';
  else return '';
};

const getRowHeight = (): GridRowHeightReturnValue => {
  return 'auto';
};

export const TableRowTypes = (isPricing?: boolean) => {
  const newTableRowTypes = {
    HEADER: 'Header',
    BODY: 'Body',
    FOOTER: 'Footer',
    TOTAL: 'Total',
  };
  const deprecatedTableRowTypes = {
    HEADER: 'Header',
    BODY: 'Body',
    FOOTER: 'Footer',
  };
  return !isPricing ? deprecatedTableRowTypes : newTableRowTypes;
};

export const getTableColumnTypes = (isPricing?: boolean) => {
  const deprecatedTableColumnTypes = { TEXT: ColumnTypeIdentifier.TEXT };

  const tableColumnTypes = {
    TEXT: ColumnTypeIdentifier.TEXT,
    MULTIPLIER: ColumnTypeIdentifier.MULTIPLIER,
    PRICE: ColumnTypeIdentifier.PRICE,
    SUBTOTAL: ColumnTypeIdentifier.SUBTOTAL,
  };
  return !isPricing ? deprecatedTableColumnTypes : tableColumnTypes;
};

export const MuiDataGrid: {
  defaultProps?: Partial<DataGridProProps<any>>;
  styleOverrides?: Partial<OverridesStyleRules>;
} = {
  defaultProps: {
    columnHeaderHeight: SIZES.columnHeaderHeight,
    showColumnVerticalBorder: true,
    hideFooter: true,
    disableColumnMenu: true,
    disableColumnSorting: true,
    getRowClassName,
    getRowHeight,
    autoHeight: true,
    columnVisibilityModel: { id: false },
  },
  styleOverrides: {
    root: {
      fontSize: '1rem',
      fontFamily: 'roboto, sans-serif',
      fontWeight: SIZES.defaultFontWeight,
      '.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--resizing': {
        color: COLORS.borderColor,
      },
      '&.py-selected-state': {
        zIndex: -1,
        '.MuiDataGrid-columnHeaders > div': { display: 'none' },
      },
      '&.py-contentful-state': {
        zIndex: 'auto',
        '.py-selected-column-cell': {
          borderLeft: STYLES.blueBorderStyle,
          borderRight: STYLES.blueBorderStyle,
          '.py-custom-input': {
            border: 'none',
            color: '#00283ADD',
            textAlign: 'right',
            paddingRight: '10px',
            fontSize: '1rem',
            '&:focus-visible': {
              outline: 'none',
              border: 'none',
            },
            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
              margin: 5,
            },
          },
        },
        '.Mui-selected': { boxShadow: STYLES.focusedBoxShadow },
      },
      '.MuiDataGrid-filler, .MuiDataGrid-scrollbar': {
        display: 'none',
      },
    },
    columnHeaderRow: {
      '--DataGrid-containerBackground': '#DDE3EA',
      color: '#41484D',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 24,
      borderBottom: '1px inner solid rgba(0, 0, 0, 0.12)',
      '.MuiDataGrid-withBorderColor': {
        borderRight: `1px solid ${COLORS.white}`,
      },
    },
    columnHeader: {
      '&:hover, &:focus, &:focus-within': {
        backgroundColor: '#A3EEFF',
        outline: 'none',
      },
    },
    row: {
      minHeight: `${SIZES.defaultRowHeight} !important`,
      '&:hover': {
        backgroundColor: 'transparent',
        '--rowBorderColor': COLORS.HoverNFocus,
        '.MuiDataGrid-cell': {
          borderBottom: '1px solid var(--rowBorderColor)',
        },
      },
      '&.py-total-row:last-child, &.py-header-row, &.py-footer-row': {
        backgroundColor: COLORS.footerHeader,
        fontWeight: 600,
        lineHeight: 'inherit',
        color: COLORS.white,
      },
      '&.py-total-row': {
        backgroundColor: COLORS.subtotalTaxDiscountBg,
        fontWeight: 600,
        color: COLORS.subtotalTaxDiscount,
      },
      '&.py-footer-row .MuiDataGrid-cell': {
        minHeight: `${SIZES.defaultRowFooterHeight}`,
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: 'transparent' },
        '&.py-total-row:last-child, &.py-header-row, &.py-footer-row': {
          backgroundColor: COLORS.footerHeader,
          '&:hover': { backgroundColor: COLORS.footerHeader },
        },
      },
    },
    cell: {
      borderBottom: '1px solid transparent',
      display: 'flex',
      alignItems: 'center',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      '&.MuiDataGrid-rowReorderCellContainer': {
        alignItems: 'unset',
        wordBreak: 'unset',
      },
      '&:focus, &.MuiDataGrid-cell--editing:focus-within': {
        outline: STYLES.blueBorderStyle,
      },
      '&.py-total-cell': {
        justifyContent: 'flex-end',
        textAlign: 'right',
      },
    },
    rowReorderCell: {
      color: 'rgba(0, 0, 0, 0.38)',
    },
    rowReorderCellPlaceholder: {
      fontWeight: SIZES.defaultFontWeight,
    },
  },
};
