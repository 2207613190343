import { ColumnTypeIdentifier, TableRowTypes } from '../../../../../muiTheme/MuiDataGrid';
import { TableColumnType } from '../../../grid/reduxStore/saveHandlers';
import { NUMBER_COLUMNS_ALLOWING_PRICING } from './variables';
import React, { useContext, useState } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { SidePanelProviderContext } from '../../../SidePanel/content';
import { useBlockContentChangedHandler } from '../../../hooks/UseBlockContentChangedHandler';
import { useTableManipulation } from './useTableManipulation';
import { SelectionContext } from '../../SelectedBlockInfoProvider';
import { useSectionData } from '../../../Sections/useSectionContext';

const HeaderInput: React.FC<Partial<GridRenderEditCellParams>> = ({ params }) => {
  const { getUpdatedRowsTableDataFromCellEdit } = useTableManipulation();
  const { selectedBlockIdByWrapper } = useContext(SelectionContext);
  const { sectionId } = useSectionData();
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { toggledTableSettingsPanel, setToggledTableSettingsPanel } = useContext(SidePanelProviderContext);
  const { id, value, field, colDef, row } = params;
  const [localValue, setLocalValue] = useState(value);
  const apiRef = useGridApiContext();

  const onCellValueChange = async (value: string) => {
    if (!selectedBlockIdByWrapper) return;
    apiRef.current.setEditCellValue({
      id,
      field,
      value,
    });
    setLocalValue(value);
    const updatedTableData = getUpdatedRowsTableDataFromCellEdit(params, value, selectedBlockIdByWrapper, sectionId);
    if (!updatedTableData) return;
    await blockContentChangedHandler(selectedBlockIdByWrapper, sectionId, updatedTableData);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && toggledTableSettingsPanel) {
      apiRef.current.stopCellEditMode({ id, field });

      const allRowIds = apiRef.current.getAllRowIds();
      const currentRowIndex = allRowIds.findIndex((rowId) => rowId === id);
      const nextRowId = allRowIds[currentRowIndex + 1];

      apiRef.current.setEditCellValue({ id, field, value: localValue });
      apiRef.current.setRowSelectionModel([nextRowId]);

      setToggledTableSettingsPanel({
        tableApi: {
          selectedModel: apiRef.current.getCellParams(nextRowId, field),
          tableCallbackDetails: toggledTableSettingsPanel.tableApi.tableCallbackDetails,
        },
      });
      apiRef.current.startCellEditMode({
        id: nextRowId,
        field,
      });
    }
  };

  return (
    <input
      className="py-custom-input"
      type={`${colDef.type === 'number' && row.rowType === TableRowTypes().BODY ? 'number' : 'string'}`}
      onChange={(e) => onCellValueChange(e.target.value)}
      onKeyDown={(e) => handlePressEnter(e)}
      value={localValue}
    />
  );
};

export function useApplyRulesToColumn() {
  const applyCustomRulesToColumns = (columns: TableColumnType[]): TableColumnType[] => {
    let updatedColumns: TableColumnType[] = [...columns];
    updatedColumns = setCustomInputToDefaultColumn(updatedColumns);
    updatedColumns = setColumnsColSpanRuleForFooter(updatedColumns);

    return updatedColumns;
  };

  const setCustomInputToDefaultColumn = (columns: TableColumnType[]): TableColumnType[] => {
    const numericColumns = [ColumnTypeIdentifier.MULTIPLIER, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.SUBTOTAL];

    return columns.map((column) => {
      if (numericColumns.includes(column?.columnType)) {
        // temporary disabled regarding the product's decision
        /*renderEditCell: (params) => {
          return <DataGridCustomAutoGrowingInput {...params} />;
        },*/
        return {
          ...column,
          renderEditCell: (params: any) => <HeaderInput params={params} />,
        };
      }
      return column;
    });
  };

  const setColumnsColSpanRuleForFooter = (columns: TableColumnType[]): TableColumnType[] => {
    const firstColumnAfterId = 1;

    columns[firstColumnAfterId] = {
      ...columns[firstColumnAfterId],
      colSpan: (_value, row): number => {
        let colSpan = 1;
        if (row.rowType === TableRowTypes().FOOTER) {
          // -1 because the first row is the id, which is not visible
          colSpan = columns.length - 1;
        }
        if (row.rowType === TableRowTypes(true)['TOTAL']) {
          if (columns.length > NUMBER_COLUMNS_ALLOWING_PRICING) {
            colSpan = columns.length - NUMBER_COLUMNS_ALLOWING_PRICING;
          } else {
            colSpan = 1;
          }
        }
        return colSpan;
      },
    };

    return columns;
  };

  return { applyCustomRulesToColumns };
}
