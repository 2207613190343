import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { TableColumnType } from '../../../../grid/reduxStore/saveHandlers';
import { ColumnTypeIdentifier, TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';

export default function calculateSubTotalsPerRow(
  rows: GridValidRowModel[],
  columns: TableColumnType[],
  precision = 5
): GridValidRowModel[] {
  const segregatedColumns = columns.reduce((acc, column) => {
    const { columnType } = column;

    if (!acc[columnType]) {
      acc[columnType] = [];
    }

    acc[columnType].push(column);
    return acc;
  }, {} as Record<ColumnTypeIdentifier, TableColumnType[]>);

  const priceColumn = segregatedColumns[ColumnTypeIdentifier.PRICE]?.[0];
  const subtotalColumn = segregatedColumns[ColumnTypeIdentifier.SUBTOTAL]?.[0];
  const multiplierColumns = segregatedColumns[ColumnTypeIdentifier.MULTIPLIER] || [];

  if (!priceColumn || !subtotalColumn) {
    return rows;
  }

  return rows.map((row) => {
    const priceValueStr = row[priceColumn.field];
    const rowTypeIsBody = row.rowType === TableRowTypes(true).BODY;

    if (!rowTypeIsBody || !priceValueStr) return row;

    let priceValue = parseFloat(priceValueStr);
    if (isNaN(priceValue)) return row;

    multiplierColumns.forEach((multiplierCol) => {
      const multiplierValueStr = row[multiplierCol.field];
      const multiplierValue = parseFloat(multiplierValueStr) || 1;
      priceValue *= multiplierValue;
    });

    const roundedSubtotal = Number(priceValue.toFixed(precision));

    return {
      ...row,
      [subtotalColumn.field]: roundedSubtotal,
    };
  });
}
