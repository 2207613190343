import { useContext, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { TableSettings } from '../../../component';
import SvgIcoTableColumnAddAfter from 'components/icon/icons/IcoTableColumnAddAfter';
import IcoTableColumnAddBefore from 'components/icon/icons/IcoTableColumnAddBefore';
import SvgIcoTrash from 'components/icon/icons/IcoTrash';
import SvgIcoContentCopy from 'components/icon/icons/IcoContentCopy';
import { useTranslation } from 'react-i18next';
import { ColumnTypeIdentifier, getTableColumnTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { useTableColumnManipulation } from './useTableColumnManipulation';
import { TableColumnAddPositions } from '../types';
import { setActiveTableSettingsPanel } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
import { useAppDispatch } from 'components/editor/grid/reduxStore/Store';
import { useSelector } from 'react-redux';
import { RootState } from 'components/editor/grid/reduxStore/Store';
import { TableSettingsTypes } from 'components/editor/grid/reduxStore/blockStyleSettingsSlice';
import useFeatureFlag from '../../../../../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../../../../../utils/featureFlags';
import { useTableColumnTypeChange } from './useTableColumnTypeChange';
import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';

const ColumnSettings = () => {
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const dispatch = useAppDispatch();
  const { handleColumnTypeChange, columnType } = useTableColumnTypeChange();
  const { activeTableSettingsPanel } = useSelector((state: RootState) => state.blockStyleSettings);
  const { t } = useTranslation();
  const { handleColumnAdd, handleColumnDuplication, handleColumnDeletion, isPriceColumnExists, isSubtotalColumnExists } =
    useTableColumnManipulation();
  const isPricingTableAvailable = useFeatureFlag([FeatureFlags.spPricingTable])[FeatureFlags.spPricingTable];
  const tableColumnTypes = getTableColumnTypes(isPricingTableAvailable);

  const columnSelectedModel = toggledTableSettingsPanel?.tableApi.selectedModel;
  const activeColumnKey = columnSelectedModel?.colDef?.columnType?.toUpperCase() as string;
  const columnTypeValue = tableColumnTypes[activeColumnKey];
  const [deprecatedColumnType, setDeprecatedColumnType] = useState<string>(columnTypeValue);

  const deprecatedHandleColumnTypeChange = (event: SelectChangeEvent) => {
    if (activeTableSettingsPanel !== TableSettingsTypes.TABLE_COLUMNS) return;
    const columnType: string = event.target.value;
    setDeprecatedColumnType(columnType);
  };

  const handleBackButtonClick = () => {
    dispatch(setActiveTableSettingsPanel({ type: null }));
  };

  const selectOptions = (option: string): boolean => {
    if (isSubtotalColumnExists && option === ColumnTypeIdentifier.SUBTOTAL) {
      return true;
    }
    if (isPriceColumnExists && option === ColumnTypeIdentifier.PRICE) {
      return true;
    }
    return false;
  };

  const buttons = [
    { icon: <SvgIcoTableColumnAddAfter />, arialLabel: 'column-add-after', fn: () => handleColumnAdd(TableColumnAddPositions.AFTER) },
    { icon: <IcoTableColumnAddBefore />, arialLabel: 'column-add-before', fn: () => handleColumnAdd(TableColumnAddPositions.BEFORE) },
    { icon: <SvgIcoContentCopy />, arialLabel: 'content-copy', fn: handleColumnDuplication },
    { icon: <SvgIcoTrash />, arialLabel: 'trash', fn: handleColumnDeletion },
  ];

  return (
    <TableSettings
      iconButtons={buttons}
      handleBackButtonClick={handleBackButtonClick}
      handleSelectChange={isPricingTableAvailable ? handleColumnTypeChange : deprecatedHandleColumnTypeChange}
      helpText={t('document.grid.table.setting.update_select_column_type')}
      panelTitle={t('document.grid.table.setting.column_type')}
      selectId="select-column-type"
      selectOptions={getTableColumnTypes(isPricingTableAvailable)}
      checkDisabled={selectOptions}
      selectValue={isPricingTableAvailable ? columnType : deprecatedColumnType}
      menuTitle={t('document.grid.table.setting.columns')}
      testId="table-column-settings"
    />
  );
};

export default ColumnSettings;
