import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { SelectChangeEvent } from '@mui/material/Select';
import { GridCellParams } from '@mui/x-data-grid-pro';

import { SidePanelContextColumnsPropsType, SidePanelProviderContext } from '../../SidePanelModelsProvider';
import { RootState } from '../../../../grid/reduxStore/Store';
import useFeatureFlag from '../../../../../../hooks/useFeatureFlag';
import { FeatureFlags } from '../../../../../../utils/featureFlags';
import { ColumnTypeIdentifier, getTableColumnTypes, TableRowTypes } from '../../../../../../muiTheme/MuiDataGrid';
import { TableSettingsTypes } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { TableType } from '../../../../grid/reduxStore/saveHandlers';
import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { useTableManipulation } from '../../../../GridDndEditor/Block/Table/useTableManipulation';
import { useBlockContentChangedHandler } from '../../../../hooks/UseBlockContentChangedHandler';

const updateColumns = (
  columns: TableType['columns'],
  columnData: GridCellParams['colDef'],
  newColumnType: ColumnTypeIdentifier,
  isNumericColumn: boolean
) => {
  return columns.map((column) =>
    column.field !== columnData?.field
      ? column
      : { ...column, headerName: newColumnType, columnType: newColumnType, type: isNumericColumn ? 'number' : 'string' }
  );
};

const updateRows = (rows: TableType['rows'], selectedColumn: string, newColumnType: ColumnTypeIdentifier, isNumericColumn: boolean) => {
  return rows.map((row) => {
    if (row.rowType !== TableRowTypes().BODY) return row;
    const isNumeric = !isNaN(Number(row[selectedColumn]));
    return isNumeric === isNumericColumn ? row : { ...row, [selectedColumn]: '' };
  });
};

export const useTableColumnTypeChange = () => {
  const { toggledTableSettingsPanel } = useContext(SidePanelProviderContext) as SidePanelContextColumnsPropsType;
  const { selectedBlockIdByWrapper, selectedSectionId } = useContext(SelectionContext);
  const blockContentChangedHandler = useBlockContentChangedHandler();
  const { getTableData } = useTableManipulation();

  const [columnType, setColumnType] = useState<string>('');

  const activeTableSettingsPanel = useSelector((state: RootState) => state.blockStyleSettings.activeTableSettingsPanel);
  const isPricingTableAvailable = useFeatureFlag([FeatureFlags.spPricingTable])[FeatureFlags.spPricingTable];

  const tableColumnTypes = useMemo(() => getTableColumnTypes(isPricingTableAvailable), [isPricingTableAvailable]);

  const getActiveColumnType = useCallback(() => {
    if (toggledTableSettingsPanel) {
      const { colDef } = toggledTableSettingsPanel.tableApi.selectedModel || {};
      const activeColumnKey = colDef?.columnType?.toUpperCase() as string;
      return tableColumnTypes[activeColumnKey] || '';
    }
    return '';
  }, [toggledTableSettingsPanel, tableColumnTypes]);

  useEffect(() => {
    const activeColumnType = getActiveColumnType();
    if (activeColumnType) setColumnType(activeColumnType);
  }, [getActiveColumnType]);

  const handleColumnTypeChange = useCallback(
    async (event: SelectChangeEvent) => {
      const isToggledPanelIncludeIds = !toggledTableSettingsPanel || !selectedBlockIdByWrapper || !selectedSectionId;
      if (activeTableSettingsPanel !== TableSettingsTypes.TABLE_COLUMNS || isToggledPanelIncludeIds) return;

      const { field: selectedColumn } = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;
      const newColumnType = event.target.value as ColumnTypeIdentifier;
      const isNumericColumn = [ColumnTypeIdentifier.SUBTOTAL, ColumnTypeIdentifier.PRICE, ColumnTypeIdentifier.MULTIPLIER].includes(
        newColumnType
      );

      const tableData = getTableData(selectedBlockIdByWrapper, selectedSectionId) as TableType;
      const { colDef: columnData } = toggledTableSettingsPanel.tableApi.selectedModel as GridCellParams;

      const updatedColumns = updateColumns(tableData.columns, columnData, newColumnType, isNumericColumn);
      const updatedRows = updateRows(tableData.rows, selectedColumn, newColumnType, isNumericColumn);

      await blockContentChangedHandler(selectedBlockIdByWrapper, selectedSectionId, {
        ...tableData,
        columns: updatedColumns,
        rows: updatedRows,
      });

      setColumnType(newColumnType);
    },
    [
      toggledTableSettingsPanel,
      selectedBlockIdByWrapper,
      selectedSectionId,
      activeTableSettingsPanel,
      getTableData,
      blockContentChangedHandler,
    ]
  );

  return {
    columnType,
    handleColumnTypeChange,
  };
};
