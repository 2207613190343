import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme, Box, Stack, Snackbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCurrentUser } from 'providers/UserProvider';
import { Icon } from 'components';
import PageLoader from 'components/page-loader/page-loader';
import { FeatureFlags } from 'utils/featureFlags';
import useFeatureFlag from 'hooks/useFeatureFlag';
import ProposifyTheme from 'muiTheme/PyTheme';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { usePlanApiData } from 'hooks/usePlanApiData';
import { useSubscriptionApiData } from 'hooks/useSubscriptionApiData';
import { useSeeAllFeaturesReducer } from 'hooks/useSeeAllFeaturesReducer';
import goToCinder from 'utils/goToCinder';

import { PlanPageProps, PlanTier } from './interface';
import { teamTopFeatures, businessTopFeatures, allPlanFeatures, basicTopFeatures } from './planFeatures';
import FeatureTable from './featureTable';
import FAQ from './faq';
import BillingHeader from './billingHeader';
import DowngradeModal from './DowngradeModal';
import SeeAllFeaturesModal from './seeAllFeaturesModal';
import PlanOverview from './planOverview';
import TooManyUsersModal from './tooManyUsersModal';
import { availableTiers, getPlanButtonLabel, getPlanTier, canChangeToPlan, isPlanUpgrade, getPlanTierByString } from '../utils/utils';

import UsersLoveUsBadge from '../../../images/badges/badge-users-love-us-g2.svg';
import Top50Badge from '../../../images/badges/badge-best-sales-product-2024.svg';
import MidMarketLeaderBadge from '../../../images/badges/badge-proposal-leader-mid-market.svg';
import MidMarketBadge from '../../../images/badges/badge-hp-mid-market-americas.svg';
import GDPRBadge from '../../../images/badges/badge-security-gdpr-large.svg';
import SOC2Badge from '../../../images/badges/badge-security-soc2-large.svg';
import PCIBadge from '../../../images/badges/badge-pci-dss.svg';
import LimitedTimeOfferModal from './LimitedTimeOfferModal';
import UpgradeSuccessModal from './upgradeSuccessModal';

const PlanPage: React.FC<PlanPageProps> = ({ planRepository, planFeatureRepository }) => {
  const { t } = useTranslation();
  const [search] = useSearchParams();

  const [isDowngradeModalOpen, setDowngradeModalOpenState] = useState(false);
  const [isLimitedTimeOfferModalOpen, setLimitedTimeOfferModalOpen] = useState(false);
  const [isPromoSnackBarOpen, setPromoSnackBarOpen] = useState(false);
  const [promoSnackBarMessage, setPromoSnackBarMessage] = useState('test');
  const [isTooManyUsersModalOpen, setIsTooManyUsersModalOpen] = useState(false);
  const [planTypeState, setPlanType] = useState('');
  const [curPlanTier, setCurPlanTier] = useState<PlanTier>('');
  const [isUpdateSuccessModalOpen, setIsUpdateSuccessModalOpen] = useState<boolean>(
    !!search.get('success') && search.get('upgrade') === 'true'
  );
  const navigate = useNavigate();

  const {
    data: { accountId },
  } = useCurrentUser();
  const { planData, isLoading, isFetched } = usePlanApiData({ planRepository, accountId, enabled: !!accountId });
  const {
    data: subscriptionData,
    isLoading: isLoadingSubscrption,
    isFetched: isFetchedSubscription,
  } = useSubscriptionApiData({ accountId, enabled: !!accountId });

  const theme = useTheme();
  const { isDesktop, isTablet, isMobile } = useMediaQuery();

  useEffect(() => {
    if (isFetched && planData) {
      setCurPlanTier(getPlanTier(planData));
    }
  }, [isFetched, planData]);

  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.planSummaryPage]);
  const newPlanSummaryFlag = evaluatedFeatureFlags[FeatureFlags.planSummaryPage];

  const badges = [UsersLoveUsBadge, Top50Badge, MidMarketLeaderBadge, MidMarketBadge, GDPRBadge, SOC2Badge, PCIBadge];
  const { state: seeAllFeaturesState, triggerDispatch: seeAllFeaturesDispatch } = useSeeAllFeaturesReducer();

  const handleTierButtonClick = useCallback(
    (planName: string) => {
      if (planData?.type === planName) {
        const planPathName = `${planName.charAt(0).toUpperCase()}${planName.slice(1)}`;
        const path = planData?.isTrialExpired ? `/trial/billing/${planPathName}` : `/settings/billing/${planPathName}`;
        navigate(path);
      } else {
        handleAdjustTier(planName);
      }
    },
    [planData?.type, curPlanTier, planData?.isTrialExpired, newPlanSummaryFlag]
  );

  const handleAdjustTier = useCallback(
    (planName: string) => {
      const newPlan: PlanTier = getPlanTierByString(planName);
      const isUpgrade: boolean = isPlanUpgrade(newPlan, curPlanTier);
      const planPathName = `${planName.charAt(0).toUpperCase()}${planName.slice(1)}`;
      if (planData?.isTrialExpired) {
        const path = planData?.isTrialExpired ? `/trial/billing/${planPathName}` : `/settings/billing/${planPathName}`;
        navigate(path);
      } else if (isUpgrade) {
        navigate(`/settings/billing/${planPathName}`);
      } else {
        if (!planData?.canSelfDowngrade) {
          goToCinder('/settings/request/downgrade');
        } else if (newPlan === 'basic' && (planData?.seats_taken || 0) > 2) {
          setIsTooManyUsersModalOpen(true);
        } else {
          setDowngradeModalOpenState(true);
          setPlanType(planName);
        }
      }
    },
    [
      isDowngradeModalOpen,
      isTooManyUsersModalOpen,
      planData?.canSelfDowngrade,
      planData?.seats_taken,
      curPlanTier,
      planData?.isTrialExpired,
    ]
  );

  const handleSeeAllFeaturesButtonClick = (plan: PlanTier) => {
    const features = {};
    const title = plan.charAt(0).toUpperCase() + plan.slice(1);
    for (const featureGroup in allPlanFeatures) {
      features[featureGroup] = allPlanFeatures[featureGroup].filter((feature) => feature[plan]);
    }

    const onConfirm = (planName) => {
      handleTierButtonClick(planName);
    };

    const confirmButtonLabel = getPlanButtonLabel(plan, curPlanTier);
    seeAllFeaturesDispatch({
      title,
      plan,
      features,
      onConfirm,
      confirmButtonLabel,
    });
  };

  const SeeAllFeaturesLink = ({ plan }: { plan: PlanTier }) => {
    return (
      <a className="see-all-features-link" href="#" onClick={() => handleSeeAllFeaturesButtonClick(plan)}>
        {t('settings.plan.see_all_features')} <Icon name="IcoArrowRight" />
      </a>
    );
  };

  const bookADemo = () => {
    window.open('https://links.proposify.com/sales/book');
  };

  return (
    <PageLoader isLoading={isLoading || isLoadingSubscrption} isLoaded={isFetched && isFetchedSubscription}>
      <ThemeProvider theme={ProposifyTheme}>
        <Box>
          <BillingHeader />
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            message={promoSnackBarMessage}
            autoHideDuration={2000}
            onClose={() => setPromoSnackBarOpen(false)}
            open={isPromoSnackBarOpen}
          />
          {planData?.id ? (
            <DowngradeModal
              planFeatureRepository={planFeatureRepository}
              isOpen={isDowngradeModalOpen}
              setModalOpen={setDowngradeModalOpenState}
              setLimitedTimeOfferModal={setLimitedTimeOfferModalOpen}
              currentPlanId={planData.id}
              planType={planTypeState}
            />
          ) : null}
          {planData?.id && subscriptionData?.uuid ? (
            <LimitedTimeOfferModal
              isOpen={isLimitedTimeOfferModalOpen}
              setModalOpen={setLimitedTimeOfferModalOpen}
              accountId={accountId}
              planRepository={planRepository}
              planType={planTypeState}
              displayPromoSnackbar={setPromoSnackBarOpen}
              promoSnackbarMessage={setPromoSnackBarMessage}
              subscription={subscriptionData}
            />
          ) : null}
          <SeeAllFeaturesModal state={seeAllFeaturesState} />
          <TooManyUsersModal
            open={isTooManyUsersModalOpen}
            onClose={() => {
              setIsTooManyUsersModalOpen(false);
            }}
          />
          {planData?.name && subscriptionData?.uuid && (
            <UpgradeSuccessModal
              planName={planData?.name || ''}
              open={isUpdateSuccessModalOpen}
              onClose={() => setIsUpdateSuccessModalOpen(false)}
            />
          )}
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" my={3}>
            <Typography variant="h5">{t('settings.plan.all_plans')}</Typography>
            <Typography variant="body1" color={theme.palette.text.primary}>
              {t('settings.plan.prices_in_usd')}
            </Typography>
          </Stack>
          <Stack direction="column" sx={{ gap: 10 }}>
            <Stack
              spacing={4}
              justifyContent="flex-start"
              alignSelf="stretch"
              alignItems="stretch"
              direction="row"
              sx={{
                pt: 2,
                overflowX: isMobile || isTablet ? 'auto' : 'visible',
                overflowY: 'visible',
                width: '100%',
              }}
              data-testid="plan-overviews"
            >
              <PlanOverview
                planTier="basic"
                title="settings.plan.basic.title"
                chipLabel="settings.plan.basic.chip"
                subtitle="settings.plan.basic.subtitle"
                billingCycle="settings.plan.basic.subtitle_billing_cycle"
                billingModel="settings.plan.basic.billing_model"
                price="settings.plan.basic.price"
                userMinimum={t('settings.plan.includes_seats', { count: 2 })}
                topFeatures={basicTopFeatures}
                topFeaturesHeader="settings.plan.basic.features_include"
                buttonLabel={getPlanButtonLabel('basic', curPlanTier, {
                  isTrialExpired: !!planData?.isTrial && !!planData?.isTrialExpired,
                  downgrade: planData?.canSelfDowngrade ? 'settings.plan.button_downgrade_plan_label' : 'settings.plan.talk_to_sales',
                })}
                onClick={() => {
                  handleTierButtonClick('basic');
                }}
                buttonDisabled={!planData?.isTrial && !canChangeToPlan('basic', curPlanTier, !!planData?.canSelfDowngrade)}
                isDowngrade={curPlanTier !== 'basic' && !isPlanUpgrade('basic', curPlanTier)}
                curPlanTier={curPlanTier}
              >
                {isTablet || isMobile ? <SeeAllFeaturesLink plan={'basic'} /> : null}
              </PlanOverview>
              <PlanOverview
                planTier="team"
                title="settings.plan.team.title"
                chipLabel="settings.plan.team.chip"
                subtitle="settings.plan.team.subtitle"
                billingCycle="settings.plan.team.subtitle_billing_cycle"
                billingModel="settings.plan.team.billing_model"
                price="settings.plan.team.price"
                topFeatures={teamTopFeatures}
                topFeaturesHeader="settings.plan.team.features_include"
                buttonLabel={getPlanButtonLabel('team', curPlanTier, {
                  isTrialExpired: !!planData?.isTrial && !!planData?.isTrialExpired,
                  downgrade: planData?.canSelfDowngrade ? 'settings.plan.button_downgrade_plan_label' : 'settings.plan.talk_to_sales',
                })}
                onClick={() => {
                  handleTierButtonClick('team');
                }}
                buttonDisabled={!canChangeToPlan('team', curPlanTier, !!planData?.canSelfDowngrade)}
                isDowngrade={curPlanTier !== 'team' && !isPlanUpgrade('team', curPlanTier)}
                curPlanTier={curPlanTier}
              >
                {isTablet || isMobile ? <SeeAllFeaturesLink plan={'team'} /> : null}
              </PlanOverview>
              <PlanOverview
                planTier="business"
                title="settings.plan.business.title"
                chipLabel="settings.plan.business.chip"
                subtitle="settings.plan.business.subtitle"
                billingCycle="settings.plan.business.subtitle_billing_cycle"
                billingModel="settings.plan.business.billing_model"
                price="settings.plan.business.price"
                userMinimum={t('settings.plan.user_minimum', { count: 10 })}
                topFeatures={businessTopFeatures}
                topFeaturesHeader="settings.plan.business.features_include"
                buttonLabel={getPlanButtonLabel('business', curPlanTier, {
                  isTrialExpired: !!planData?.isTrial && !!planData?.isTrialExpired,
                })}
                onClick={() => {
                  handleTierButtonClick('business');
                }}
                buttonLabelSecondary={canChangeToPlan('business', curPlanTier) ? 'settings.plan.button_contact_plan_label' : undefined}
                buttonDisabled={!canChangeToPlan('business', curPlanTier, !!planData?.canSelfDowngrade)}
                onClickSecondary={canChangeToPlan('business', curPlanTier, !!planData?.canSelfDowngrade) ? bookADemo : undefined}
                buttonDisabledSecondary={!canChangeToPlan('business', curPlanTier, !!planData?.canSelfDowngrade)}
                isDowngrade={curPlanTier !== 'business' && !isPlanUpgrade('business', curPlanTier)}
                curPlanTier={curPlanTier}
              >
                {isTablet || isMobile ? <SeeAllFeaturesLink plan={'business'} /> : null}
              </PlanOverview>
            </Stack>

            {isDesktop && (
              <FeatureTable
                features={allPlanFeatures}
                tiers={availableTiers}
                onSwitchPlan={handleTierButtonClick}
                onSwitchSecondary={canChangeToPlan('business', curPlanTier, !!planData?.canSelfDowngrade) ? bookADemo : undefined}
                currentPlanTier={curPlanTier}
                planData={planData}
              />
            )}

            <Stack direction="row" gap={2} alignSelf="center" justifyContent="center" flexWrap="wrap" sx={{ mx: 2 }}>
              {badges.map((badge, key) => (
                <img key={`badge-${key}`} src={badge} style={{ height: '100px', width: 'auto' }} />
              ))}
            </Stack>
            <FAQ pausable={!!planData?.pausable} cancelable={!!accountId && !planData?.pausable} />
          </Stack>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default PlanPage;
