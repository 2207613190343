import { ColumnTypeIdentifier } from '../../../../../muiTheme/MuiDataGrid';

export const defaultRowMeta = {
  __reorder__: 'Row',
};

export const NUMBER_COLUMNS_ALLOWING_PRICING = 3;
export const pricingRowsMeta = {
  defaultRowMeta: defaultRowMeta,
  totalRowMeta: {
    __reorder__: 'Total',
  },
};

export const defaultColumnMeta = {
  editable: true,
  headerName: 'Text',
  columnType: ColumnTypeIdentifier.TEXT,
};

export const defaultPricingColumnMeta = [
  {
    editable: true,
    headerName: 'Name',
    columnType: ColumnTypeIdentifier.TEXT,
  },
  {
    editable: true,
    headerName: 'Quantity',
    columnType: ColumnTypeIdentifier.MULTIPLIER,
  },
  {
    editable: false,
    headerName: 'Price',
    columnType: ColumnTypeIdentifier.PRICE,
  },
  {
    editable: true,
    headerName: 'Subtotal',
    columnType: ColumnTypeIdentifier.SUBTOTAL,
  },
];

export const initialColumnWidth = 333;
export const initialPricingColumnWidth = 180;

export const cellFocusOutEventKeys = ['Enter', 'Tab'];
